/** Class that creates a polling object
 * @description Must implement Hooks: onSuccess and onFail.
 * Optionally implement isValidPulse
 */
// Example of implementing isValidPulse would look liket this
// const hearbeat = new Hearbeat()
// heartbeat.isValidPulse = (response) => {
//     validation based on response
//
//
//
//
// }
class Heartbeat {

    /**
     * @param {number} bpm Number of polls per minute. Default to 30
     * @param {number} tries Number of times to poll before bailing. Default to 30
    */
    constructor(bpm = 30, tries = 30) {
      this.endpoint = null;
      this.bpm = bpm;
      this.interval = 60 / bpm * 1000;
      this.isBeating = false;
      this.triesCount = 0;
      this.maxTries = tries;
      this.response = null;
      this.options = {};
      // Hooks
      this.onSuccess = (response) => { };
      this.onFail = (error) => { };
    }

    kill() {
      this.isBeating = false;
    }

    /**
     * Starts the polling
     * @param {string} endpoint - The route that will be polled
     * @param {object} options - Fetch API options object
     */
    start(endpoint, options = {}) {
      if (!endpoint) return
      this.options = options;
      this.triesCount = 0;
      this.isBeating = true;
      this.endpoint = endpoint;
      this.beat();
    }

    /**
     * @param {Response} response
     * @returns {Promise<boolean>}
     */
    isValidPulse(response) {
      return new Promise((resolve, reject) => {
        if (response.status == 200) {
          resolve(true);
        } else if (response.status == 206) {
          resolve(false);
        } else {
          reject("Unexpected status");
        }
      })
    }

    async beat() {
      while (this.triesCount <= this.maxTries && this.isBeating) {
        let response, isValidPulse;
        this.triesCount++;
        try {
          response = await fetch(this.endpoint, this.options);
          isValidPulse = await this.isValidPulse(response);
        } catch (err) {
          this.kill();
          this.onFail("aborted");
          return;
        }
        if (isValidPulse) {
          this.kill();
          this.onSuccess(response);
          return;
        }
        await new Promise((resolve) => { setTimeout(() => resolve(), this.interval) });
      }
      this.kill();
      this.onFail("limit");
    }
  }

export default Heartbeat;
