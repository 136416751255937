import Heartbeat from "../../../../../common/modules/utilities/Heartbeat"

class Jifiti {
    constructor() {
        if (!window.isJifitiPage) return;
        this.init();

    }

    init() {
        window.addEventListener('message', e => {
            if (!e.origin.includes("jifiti.com") || e.data?.event != "success") return;
            const csrftoken = document.cookie
                .split("; ")
                .find((row) => row.startsWith("csrftoken="))
                ?.split("=")[1];
            const queryParams = new URLSearchParams();
            queryParams.append("i", window.itemNumber);
            const heartbeat = new Heartbeat();
            heartbeat.onSuccess = async (response) => {
                const respBody = await response.json();
                window.location.href = respBody.redirect_url;
            }
            heartbeat.onFail = (reason) => {
                document.getElementById("error_overlay").style.display = "flex";
            }
            heartbeat.start(`/sendcard/gifts/transaction-started?${queryParams}`, {
                method: "GET",
                headers: {
                    'X-Csrftoken': csrftoken,
                },
            });
        })
    }
}

export default Jifiti